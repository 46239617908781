import React, { Fragment } from "react";
import Login from "./login";
import Seo from "../components/section/seo";
import Main from "../components/main";

const IndexPage = () => {
  return (
    <Fragment>
      <Main>
        <Seo title="Scape - Internet Provider">
          <meta name="description" content="Scape Internet Service Provider" />
        </Seo>
        <Login/>
      </Main>
    </Fragment>
  );
}

export default IndexPage;